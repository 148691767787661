import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Modal from './Modal'; // Import your Modal component
import QRCode from 'qrcode.react'; // Import the QRCode component
import logo from './img/yes.jpg';
import './InvoiceDetail.css'; // Import a new CSS file for styling
import api from './config';
import html2canvas from 'html2canvas';

const InvoiceDetail = () => {
    const { id } = useParams();
    const [invoice, setInvoice] = useState(null);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    
    const [showSecretModal, setShowSecretModal] = useState(false); // New state for secret code modal
    const [secretCode, setSecretCode] = useState(''); // State to store inputted secret code
    const [actionType, setActionType] = useState(''); // To differentiate between edit and delete actions

    const correctSecretCode = '12345'; // Define the correct secret code here

    // Editable fields state
    const [formValues, setFormValues] = useState({
        asset_description: '',
        asset_make: '',
        vendor_name: '',
        invoice_number: '',
        invoice_date: new Date(),
        installation_date: new Date(),
        location: '',
        warrantee: '',
    });

    // QR code size state
    const [qrSize, setQrSize] = useState(37.5); // Default size in mm
    const [qrSizePixels, setQrSizePixels] = useState(150); // Default size in pixels

    const loadInvoiceDetails = async () => {
        try {
            const response = await axios.get(`${api}/getByIdAssets.php?asset_id=${id}`);
            setInvoice(response.data);
            setFormValues({
                asset_description: response.data.asset_description || '',
                asset_make: response.data.asset_make || '',
                vendor_name: response.data.vendor_name || '',
                invoice_number: response.data.invoice_number || '',
                invoice_date: response.data.invoice_date ? new Date(response.data.invoice_date) : new Date(),
                installation_date: response.data.installation_date ? new Date(response.data.installation_date) : new Date(),
                location: response.data.location || '',
                warrantee: response.data.warrantee || '',
            });
        } catch (err) {
            console.error('Error fetching invoice details:', err);
            setError('Failed to load invoice details. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadInvoiceDetails();
    }, [id]);

    const handleDeleteInvoice = async () => {
        try {
            await axios.delete(`${api}/deleteAssets.php?asset_id=${id}`);
            alert('Invoice deleted successfully.');
        } catch (err) {
            console.error('Error deleting invoice:', err);
            alert('Failed to delete invoice. Please try again later.');
        }
    };

    const handleEditInvoice = async () => {
        try {
            await axios.put(`${api}/editAssets.php`, {
                asset_id: id,
                ...formValues,
            });
            alert('Invoice updated successfully.');
            setIsEditing(false); // Exit editing mode after successful update
            loadInvoiceDetails(); // Reload invoice details to reflect changes
        } catch (err) {
            console.error('Error updating invoice:', err);
            alert('Failed to update invoice. Please try again later.');
        }
    };

    const printInvoice = () => {
        window.print(); // Trigger the browser's print functionality
    };

    const downloadQRCode = () => {
        const qrElement = document.querySelector('.qr-code-wrapper');
        html2canvas(qrElement).then(canvas => {
            const pngUrl = canvas.toDataURL('image/png');
            const link = document.createElement('a');
            link.href = pngUrl;
            link.setAttribute('download', `Invoice_QR_Code_${id}.png`);
            document.body.appendChild(link);
            link.click();
            link.remove();
        });
    };
    const handleQrSizeChange = (change) => {
        const newSize = qrSize + change;
        if (newSize > 0) {
            setQrSize(newSize);
            setQrSizePixels(newSize * 4); // Assuming 1 mm = 4 pixels
        }
    };

    // New function to handle secret code validation
    const handleSecretCodeSubmit = () => {
        if (secretCode === correctSecretCode) {
            if (actionType === 'delete') {
                handleDeleteInvoice();
            } else if (actionType === 'edit') {
                setIsEditing(true);
            }
            setShowSecretModal(false); // Close the modal after validation
        } else {
            alert('Invalid secret code!');
        }
    };

    if (loading) {
        return <div className="loading">Loading...</div>;
    }

    if (error) {
        return <div className="error">{error}</div>;
    }

    const qrCodeUrl = `https://ajay-hamaradhikar-com.preview-domain.com/#/invoice/${id}`; // Replace with your actual URL

    return (
        <div className="invoice-detail-container">
            <div className="headerr">
                <div className='resixe'>
                    <h1>Asset Details</h1>
                    <img src={logo} alt="Logo" className="logo" />
                </div>
            </div>
            <div className="invoice-info">
                <div className="info-item">
                    <strong>Asset Description:</strong> {invoice.asset_description}
                </div>
                <div className="info-item">
                    <strong>Asset Make:</strong> {invoice.asset_make}
                </div>
                <div className="info-item">
                    <strong>Vendor Name:</strong> {invoice.vendor_name}
                </div>
                <div className="info-item">
                    <strong>Invoice Number:</strong> {invoice.invoice_number}
                </div>
                <div className="info-item">
                    <strong>Invoice Date:</strong> {new Date(invoice.invoice_date).toLocaleDateString()}
                </div>
                <div className="info-item">
                    <strong>Installation Date:</strong> {new Date(invoice.installation_date).toLocaleDateString()}
                </div>
                <div className="info-item">
                    <strong>Location:</strong> {invoice.location}
                </div>
                <div className="info-item">
                    <strong>Warranty:</strong> {invoice.warrantee}
                </div>
            </div>

            <div className="action-buttons">
                <button onClick={() => { setShowSecretModal(true); setActionType('edit'); }} className="edit-button">Edit Assets</button>
                <button onClick={() => { setShowSecretModal(true); setActionType('delete'); }} className="delete-button">Delete Assets</button>
                <button onClick={printInvoice} className="download-button">Assets Invoice</button>
            </div>

            {/* QR Code Display */}
           <div className="qr-code-section">
    <h2>Assets QR Code</h2>
    <div className="qr-code-wrapper">
        <p>Asset Serial Number: {invoice.invoice_number}</p> {/* Display serial number */}
        <QRCode value={qrCodeUrl} size={qrSizePixels} />
        <p>Scan this QR code to view the Asset Details</p>
    </div>
    <button onClick={downloadQRCode} className="download-qr-button">Download QR Code</button>
    <div className='qr-size-controls'>
        <button onClick={() => handleQrSizeChange(-5)}>-</button>
        <span>{qrSize} mm</span>
        <button onClick={() => handleQrSizeChange(5)}>+</button>
    </div>
</div>
           
            {/* Secret Code Modal */}
            <Modal isOpen={showSecretModal} onClose={() => setShowSecretModal(false)}>
                <h2>Enter Secret Code</h2>
                <input
                    type="password"
                    value={secretCode}
                    onChange={(e) => setSecretCode(e.target.value)}
                    className="secret-code-input"
                    placeholder="Enter secret code"
                />
                <div className="modal-actions">
                    <button onClick={handleSecretCodeSubmit} className="submit-button">Submit</button>
                    <button onClick={() => setShowSecretModal(false)} className="cancel-button">Cancel</button>
                </div>
            </Modal>

            {/* Edit Invoice Modal */}
            <Modal isOpen={isEditing} onClose={() => setIsEditing(false)}>
                <h2>Edit Invoice</h2>
                {Object.keys(formValues).map((key) => (
                    <div key={key} className="form-item">
                        <label>{key.replace(/_/g, ' ')}:</label>
                        {(key === 'installation_date' || key === 'invoice_date') ? (
                            <DatePicker
                                selected={formValues[key]}
                                onChange={(date) => setFormValues({ ...formValues, [key]: date })}
                                dateFormat="yyyy-MM-dd"
                            />
                        ) : (
                            <input
                                type="text"
                                value={formValues[key]}
                                onChange={(e) => setFormValues({ ...formValues, [key]: e.target.value })}
                            />
                        )}
                    </div>
                ))}
                <div className="modal-actions">
                    <button onClick={handleEditInvoice} className="submit-button">Submit</button>
                    <button onClick={() => setIsEditing(false)} className="cancel-button">Cancel</button>
                </div>
            </Modal>
        </div>
    );
};

export default InvoiceDetail;
