import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './LoginPage.css';
import api from './config';

const LoginPage = () => {
  const [number, setNumber] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [secretNumber, setSecretNumber] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [loading, setLoading] = useState(false);
  
  const navigate = useNavigate();
  const SECRET_KEY = '1180';

  const handleLogin = async () => {
    setLoading(true);
    setError('');

    if (!number || !password) {
      setLoading(false);
      setError('Please fill in all fields.');
      return;
    }

    try {
      const response = await fetch(`${api}/Login.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          number,
          password,
        }),
      });

      const result = await response.json();
      setLoading(false);

      if (response.ok) {
        localStorage.setItem('user', JSON.stringify(result.user));
        window.location.href = '/home';
       
      } else {
        setError(result.error || 'Login failed. Please try again.');
      }
    } catch (error) {
      setLoading(false);
      setError('An error occurred. Please try again.');
    }
  };

  const handleForgetPassword = async () => {
    if (secretNumber !== SECRET_KEY) {
      setError('Invalid secret number');
      return;
    }

    if (!newPassword) {
      setError('Please enter a new password.');
      return;
    }

    setLoading(true);
    setError('');

    try {
      const response = await fetch(`${api}/forget.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          number,
          new_password: newPassword,
        }),
      });

      const result = await response.json();
      setLoading(false);

      if (response.ok) {
        setShowForgotPassword(false);
        alert('Password updated successfully!');
      } else {
        setError(result.error || 'Failed to update password.');
      }
    } catch (error) {
      setLoading(false);
      setError('An error occurred. Please try again.');
    }
  };

  return (
    <div className="auth-container">
      <div className="auth-form-wrapper">
        <div className="auth-header">
          <h1>Yes Seal Trust</h1>
          <h2>Welcome Back</h2>
          <p>Sign in to continue</p>
        </div>

        {!showForgotPassword ? (
          <>
            <input
              className="auth-input"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
              placeholder="Number"
              type="text"
            />
            <input
              className="auth-input"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              type="password"
            />
            {error && <p className="auth-error">{error}</p>}
            <button className="auth-button" disabled={loading} onClick={handleLogin}>
              {loading ? 'Loading...' : 'LOGIN'}
            </button>
            <button className="auth-forgot" onClick={() => setShowForgotPassword(true)}>
              Forgot Password?
            </button>
          </>
        ) : (
          <>
            <input
              className="auth-input"
              value={secretNumber}
              onChange={(e) => setSecretNumber(e.target.value)}
              placeholder="Enter secret number"
              type="text"
            />
            <input
              className="auth-input"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="Enter new password"
              type="password"
            />
            {error && <p className="auth-error">{error}</p>}
            <button className="auth-button" onClick={handleForgetPassword} disabled={loading}>
              {loading ? 'Loading...' : 'Update Password'}
            </button>
            <button className="auth-forgot" onClick={() => setShowForgotPassword(false)}>
              Remember your password? Login here
            </button>
          </>
        )}
        {!showForgotPassword && (
          <div className="auth-create-account">
            <p>Don't have an account? </p>
            <button onClick={() => navigate('/register')}>Create a new account</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default LoginPage;
