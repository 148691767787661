import React from 'react';
import { HashRouter, Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from './LoginPage';
import RegisterPage from './RegisterPage';
import HomePage from './HomePage';
import InvoiceDetail from './InvoiceDetail';

const App = () => {
    const isAuthenticated = () => !!localStorage.getItem('user'); // Check if user is authenticated

    return (
        <HashRouter>
            <Routes>
                {/* Redirect to /home if authenticated, else show LoginPage */}
                <Route path="/" element={isAuthenticated() ? <Navigate to="/home" /> : <LoginPage />} />
                <Route path="/login" element={isAuthenticated() ? <Navigate to="/home" /> : <LoginPage />} />
                <Route path="/register" element={<RegisterPage />} />
                {/* Protect InvoiceDetail route */}
                <Route path="/invoice/:id" element={isAuthenticated() ? <InvoiceDetail /> : <Navigate to="/login" />} />
                {/* Protect HomePage route */}
                <Route path="/home" element={isAuthenticated() ? <HomePage /> : <Navigate to="/login" />} />
            </Routes>
        </HashRouter>
    );
};

export default App;
