import React, { useState } from 'react';
import './RegisterPage.css'; // Updated CSS file name
import { useNavigate } from 'react-router-dom';
import api from './config';

const RegisterPage = () => {
    const [name, setName] = useState('');
    const [number, setNumber] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');

    const navigate = useNavigate();

    const handleRegister = () => {
        if (password !== confirmPassword) {
            alert("Passwords do not match");
            return;
        }

        const userData = {
            name: name,
            number: number,
            password: password
        };

        fetch(`${api}/read.php`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userData),
        })
        .then(response => response.json())
        .then(data => {
            if (data.message) {
                alert(data.message);
            } else {
                alert(data.error || "Something went wrong");
            }
        })
        .catch(error => {
            console.error('Error:', error);
            alert("Failed to register user");
        });
    };

    return (
        <div className="register-container">
            <div className="register-header">
                <h1 className="register-logo">Yes Seal Trust</h1>
                <h2 className="register-title">Create Account</h2>
            </div>
            <div className="register-form">
                <input
                    className="register-input"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    autoCapitalize="none"
                />
                <input
                    className="register-input"
                    placeholder="Number"
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                    type="tel"
                    autoCapitalize="none"
                />
                <input
                    className="register-input"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    autoCapitalize="none"
                />
                <input
                    className="register-input"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    type="password"
                    autoCapitalize="none"
                />
                {error && <p className="register-error">{error}</p>}
                <button className="register-button" onClick={handleRegister}>
                    Register
                </button>
                <button className="register-login-link" onClick={() => navigate('/login')}>
                    Already have an account? Login here
                </button>
            </div>
        </div>
    );
};

export default RegisterPage;
